.react-calendar {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  color: #222;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  border-style: solid;
  border-color: #4096ff;
  border-width: 0px 0px 0px 0px;
}

abbr[title] {
  text-decoration: none;
 }
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  background: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: medium;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.25em;
}

.react-calendar__tile {
  width: 100%;
  background: none;
  text-align: start;
  line-height: 16px;
  display: flex;
  height: 150px;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #dddddd;
}
.react-calendar__tile--active {
  border-radius: 6px;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;

  border-style: solid;
  border-color: #4096ff;
  border-width: 0px 0px 0.25px 0px;
}

.react-calendar__navigation__arrow {
  flex: 1;
  min-width: 44px;
  background: none;
  font-size: x-large;
  justify-content: center;
  align-items: center;
}
.react-calendar__month-view__weekdays__weekday {
  display: flex;
  border-style: solid;
  border-width: 0.25px;
  border-color: #dddddd;
}
.react-calendar__month-view__days .react-calendar__month-view__days__day:nth-child(7n){
  display: flex;
  border-style: solid;
  border-width: 0.25px 0px 0.25px 0.25px;
}
.react-calendar__month-view__days .react-calendar__month-view__days__day:nth-child(7n + 1) {
  border-width: 0.25px 0.25px 0.25px 0px;
}
/* .react-calendar__month-view__days .react-calendar__month-view__days__day:nth-child(7n) {
  display: flex;
  border-style: solid;
  border-width: 0.25px 0px 0.25px 0.25 px;
  border-color: #dddddd;
} */
.react-calendar__navigation__next-button {
  display: flex;
}
.react-calendar__navigation button:disabled {
  background-color: #fff;
  color: #000;
}
.react-calendar__navigation__label {
  width: 10px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__days .react-calendar__tile {
  width: 100%;
  background: none;
  text-align: start;
  line-height: 16px;
  display: flex;
  height: 150px;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  border-style: solid;
  border-width: 0.25px;
  border-color: #dddddd;
}

.react-calendar__viewContainer {
  border-style: solid;
  border-width: 0px 0px 0.25px 0px;
  border-color: #dddddd;
}
.react-calendar__month-view__weekdays__weekday {
  color: rgb(34, 34, 34);
}
.react-calendar__month-view__weekdays__weekday:first-child {
  border-width: 0.25px 0.25px 0.25px 0px;
}
.react-calendar__month-view__weekdays__weekday:last-child {
  border-width: 0.25px 0px 0.25px 0.25px;
  color: red;
}
.react-calendar__month-view__days .react-calendar__month-view__days__day:nth-child(7n) abbr{
  color: red;
}
.react-calendar__month-view__days .react-calendar__tile--now abbr {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1890ff;
  color: white;
  padding: 4px;
  border-radius: 13px;
  min-width: 22px;
  max-width: 25px;
}

.react-calendar__navigation__label__labelText .react-calendar__navigation__label__labelText--from {
  white-space: nowrap;
}
.react-calendar__navigation__label {
  min-width: 100px;
  white-space: nowrap;
}

/* 경고 메시지로 인해 항목이 위로 향하는거 방지 */
.ant-form-item {
  margin-bottom: 0px;
}