body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-popover .ant-popover-inner {
  padding: 14px;
}

.ant-popconfirm .ant-popconfirm-message {
  margin-bottom: 15px;
}

.ant-btn-sm {
  padding: 0px 15px;
} 
.ant-input-outlined:focus {
  box-shadow: none;
}

.ant-divider-horizontal {
  margin: 0px;
}

.ant-btn {
  font-size: 13px;
  /* padding: 4px 11px; */
}

.ant-menu {
  font-size: 14px;
}

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle .ant-table-title, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle .ant-table-footer, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle .ant-table-cell, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>th, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle tfoot>tr>th, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table.ant-table-middle tfoot>tr>td {
 padding: 7px 8px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-radio-button-wrapper {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-select-multiple {
  font-size: 12px;
} */
:where(.css-dev-only-do-not-override-4oxdqr).ant-select-multiple .ant-select-selector:after {
  line-height: 21px;
}

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-input-affix-wrapper {
  font-size: 12px;
} */
/* :where(.css-dev-only-do-not-override-4oxdqr).ant-btn.ant-btn-icon-only .anticon {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-picker {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-picker .ant-picker-input >input {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-popover {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-select-single .ant-select-selector {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-segmented {
  font-size: 12px;
} */

/* :where(.css-dev-only-do-not-override-4oxdqr).ant-radio-wrapper {
  font-size: 12px;
} */
